// browser.service.ts
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  // Window utilities
  getWindow(): Window | null {
    return this.isBrowser ? window : null;
  }

  // LocalStorage utilities
  getItem(key: string): string | null {
    return this.isBrowser ? localStorage.getItem(key) : null;
  }

  setItem(key: string, value: string): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }

  // SessionStorage utilities
  getSessionItem(key: string): string | null {
    return this.isBrowser ? sessionStorage.getItem(key) : null;
  }

  setSessionItem(key: string, value: string): void {
    if (this.isBrowser) {
      sessionStorage.setItem(key, value);
    }
  }

  // Document utilities
  getDocument(): Document | null {
    return this.isBrowser ? document : null;
  }

  // Navigator utilities
  getNavigator(): Navigator | null {
    return this.isBrowser ? navigator : null;
  }

  // Cookie utilities
  getCookie(name: string): string | null {
    if (!this.isBrowser) return null;

    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setCookie(name: string, value: string, days?: number): void {
    if (!this.isBrowser) return;

    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  // Screen utilities
  getScreenSize(): { width: number; height: number } | null {
    if (!this.isBrowser) return null;
    return {
      width: window.screen.width,
      height: window.screen.height
    };
  }

  // User Agent utilities
  getUserAgent(): string | null {
    if (!this.isBrowser) return null;
    return window.navigator.userAgent;
  }
}

// browser.decorator.ts
export function BrowserOnly() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const browserService = this.injector.get(BrowserService);
      if (browserService.getWindow()) {
        return originalMethod.apply(this, args);
      }
      return null;
    };

    return descriptor;
  };
}
