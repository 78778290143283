import { Injectable } from '@angular/core';
import { RouteModel } from '../models/route.model';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  routesDictionary: RouteModel[];
  currentLanguaje: string;
  counter = 0;
  activeLocation: string;

  constructor() {
    this.routesDictionary = [];
  }

  addRoutes(route: RouteModel) {
    if (
      !this.routesDictionary.find((item) => {
        if (item.key === route.key && item.ofModule === route.ofModule) {
          return true;
        }
      })
    ) {
      this.routesDictionary.push(route);
    }
  }

  getRoute(key: string, ofModule: string): string {
    this.counter++;
    // console.warn(this.counter);
    const routerModel = this.routesDictionary.find((item) => {
      return item.ofModule === ofModule && item.key === key;
    });

    try {
      let route = routerModel.routesWithLanguageModels.find((item) => {
        if (item.language === this.currentLanguaje) {
          return true;
        }
      }).route;
      if (
        !route.includes('/pt/') &&
        !route.includes('/es/') &&
        !route.includes('/en/') &&
        route.startsWith('/')
      ) {
        route = '/' + this.currentLanguaje + '/' + route;
      }
      this.activeLocation = routerModel.key;
      return route;
    } catch (error) {
      console.log(key);
      console.log(ofModule);
      return key;
    }
  }

  /**
   * Get the route key using the routeUrl and the module
   *
   * @param {string} route
   * @param {string} ofModule
   * @returns {string} Key of the Route seached
   * @memberof UrlService
   */
  getKey(route: string, ofModule: string): string {
    let key: string;

    this.routesDictionary.forEach((routesOfModule) => {
      if (routesOfModule.ofModule === ofModule) {
        routesOfModule.routesWithLanguageModels.forEach((routeWithLanguage) => {
          if (routeWithLanguage.route === route) {
            key = routesOfModule.key;
          }
        });
      }
    });

    return key;
  }

  correctRoute(element: string, lang: string) {
    let routeString: string;

    this.routesDictionary.find((routeModels) => {
      if (routeModels.routesWithLanguageModels !== undefined) {
        const aux = routeModels.routesWithLanguageModels.find(
          (routeWithLanguageModels) => {
            if (
              routeWithLanguageModels.route !== undefined &&
              routeWithLanguageModels.route !== null
            ) {
              if (
                routeWithLanguageModels.route !== undefined &&
                routeWithLanguageModels.route === element
              ) {
                return true;
              }
            }
          }
        );
        if (aux !== undefined) {
          this.activeLocation = routeModels.key;
          const routeWithLanguageModel =
            routeModels.routesWithLanguageModels.find(
              (routeWithLanguageModels) => {
                return routeWithLanguageModels.language === lang;
              }
            );
          routeString = routeWithLanguageModel.route;
          return true;
        }
      }
    });

    return routeString;
  }
}
