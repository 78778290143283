import { Globals } from 'src/shared/globalVariables';
export const environment = {
  production: true,
};

Globals.url = 'https://www.playbonds.com';
Globals._API2URL = '/api2/'; // Para cambiar, se debe hacer en proxy.conf.json en modo dev
Globals._APIURl = ''; // SSR Prod
// Globals._APIURl = 'http://playbonds-website-v2019-api.ativogames.com'; // SSR Dev
// Globals._APIURl = 'http://localhost:21021'; SSR localhost server

Globals._imagePrefixUrl = '//cdn.playbonds.com';
Globals._signalR = '//notification.playbonds.com/NotificationHub';
Globals._bingoRoomID = 4344; //758 patagonia 3451 end2end y bingolar;
