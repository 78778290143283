export class CurrencyModel {
  currencySymbol: string;
  currencyId: number;

  // constructor(currencySymbol: string, currencyId: number) {
  //   this.currencySymbol = currencySymbol;
  //   this.currencyId = currencyId;
  // }
  constructor() {}
}
