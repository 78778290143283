import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslateServiceProxy } from '../service-proxies/service-proxies';

export class CamaleonTranslateLoader implements TranslateLoader {
  moduleName: string;
  /**
   *
   */
  constructor(
    public translateService: TranslateServiceProxy,
    moduleName: string
  ) {
    this.moduleName = moduleName;
  }

  getTranslation(lang: string): Observable<any> {
    return this.translateService.getTranslatesFromPage(this.moduleName, lang);
  }
}
