import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { HubConnection, HubConnectionFactory } from '@ssv/signalr-client';
import { stat } from 'fs';
// import * as signalR from '@aspnet/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { Globals } from 'src/shared/globalVariables';
import { ClientCurrentBalanceDto } from 'src/shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private connection: signalR.HubConnection;
  public messages: string[];
  onBalanceUpdate = new BehaviorSubject<ClientCurrentBalanceDto>(null);

  conn: HubConnection<any>;
  public status= 'disconnected';
  public lastRoute= '';
  constructor(
    private factory: HubConnectionFactory,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.messages = [];


  this.router.events.subscribe((event: Event) => {
    if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.lastRoute = event.url;

        if (this.status == "connected"){
          this.conn
          .send('ClientGoesToPage', this.lastRoute)
          .subscribe(() => {});
        }
    }
  });
  }

  connect(): Observable<any> {
    if (!this.conn && !!Globals._signalR) {
      this.conn = this.factory
        .create({
          key: 'notificationHub',
          endpointUri: Globals._signalR,
        })
        .get('notificationHub');


        this.conn.connectionState$.subscribe((status) => {
          this.status = status.status;
        });
      // this.connection = new signalR.HubConnectionBuilder()
      //   .withUrl(
      //     'http://playbonds-website-v2019-notificate.ativogames.com/NotificationHub',
      //     {
      //       accessTokenFactory: () => accessToken
      //     }
      //   )
      //   .build();
      // // this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 10; // 1 second * 60 * 10 = 10 minutes.

      // this.connection.on('BalanceMessage', msg => {
      // console.log('Received ', msg);
      // // this.messages.push( msg );
      // const balance: ClientCurrentBalanceDto = JSON.parse(msg);
      // this.onBalanceUpdate.next(balance);
      // });
      // return this.connection.start(); /*.catch(err => console.error(err)) */
    }
    return this.conn.connect().pipe();
  }

  login(userId: number, hash: string): Observable<unknown> {
    console.log("CALL LOGIN");
    let login = this.conn.send('Login', userId, hash);

    login.subscribe((x)=>{
        console.log(x);
        this.conn
        .send('ClientGoesToPage', this.lastRoute)
        .subscribe(() => {});

    });

    return login;
  }

  sendMessage(userId: number, message: string) {
    this.connection.invoke('sendMessage', userId, message);
  }

  balanceSignalObserver(): Observable<any> {
    return this.onBalanceUpdate;
    // const balanceObservable = new Observable(observer => {
    //        setTimeout(() => {
    //            observer.next(this.messages );
    //        }, 3000);
    // });
    // return balanceObservable;
  }

  disconnect() {
    if (this.conn) {
      this.conn
        .disconnect()
        .pipe()
        .subscribe(() => {
          console.log('SR disconnected');
        });
    }
  }
}
