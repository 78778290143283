import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BrowserService } from './browser.services';

@Injectable()
export class DataFromUrlService {
  // este buscar en la URL y guardar el LOCALSTORAGE:
  // http://es.playbonds.com/?ShowRegister=1&AffiliateId=2888&Promocode=vb60

  dataToStore = [
    'promocode',
    'affiliateid',
    'showregister',
    'validate-cpf',
    'codigo-amigo',
    'clientid_indicator',
  ];
  storeButtonFlag; // se usa para la store, si se da click al boton queda true

  constructor(
    private browserService: BrowserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (!isPlatformServer(platformId)) {
      if (document !== undefined && document !== null) {
        // url:
        const loc = document.location.href;

        // to test:
        // loc = 'http://es.playbonds.com/?ShowRegister=1&AffiliateId=2888&Promocode=vb60';

        if (loc.indexOf('?') > 0) {
          const getString = loc.split('?')[1];
          const GET = getString.split('&');

          for (let i = 0; i < GET.length; i++) {
            const aux = GET[i].split('=');
            if (
              this.dataToStore.indexOf((aux[0] as string).toLowerCase()) !== -1
            ) {
              localStorage.setItem((aux[0] as string).toLowerCase(), aux[1]);
            }
          }
        }
      }
    }
  }

  getData(type: string) {
    return this.browserService.getItem(type);
  }
}
