import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ConnectionState, ConnectionStatus } from '@ssv/signalr-client';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  Observable,
  PartialObserver,
  forkJoin,
  merge,
  of,
} from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';
import { Globals } from '../globalVariables';
import { AuthModel } from '../models/auth.model';
import { CurrencyModel } from '../models/currency-model';
import {
  AuthenticateModel,
  AuthenticateResultModel,
  GetCurrentLoginInformationsOutput,
  SessionServiceProxy,
  TokenAuthServiceProxy,
} from '../service-proxies/service-proxies';
import { DataFromUrlService } from './dataFromUrl.service';
import { SignalRService } from './service/signalr-service.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authModel: AuthenticateModel;
  currencyModel: CurrencyModel;
  messageCount: number;
  miles = 0;

  obsLocalStorageAuthResultModel: any;
  localStorageObsAuthResultModel: any;

  localSymbol: string;
  currencySymbol: string;
  currencyId: number;

  // NF
  currentAuthInfo: AuthModel;
  //   authObs: Observable<AuthenticateResultModel>;
  onAuth = new BehaviorSubject<AuthModel>(null);
  onLoading = new BehaviorSubject<boolean>(true);

  onCurrencyLoad = new BehaviorSubject<CurrencyModel>(null);
  favoritesCount: number;
  connectionStatus: ConnectionStatus;

  currentBlock = false; //keep the last value of the observable
  public currentBlockObs = new BehaviorSubject<boolean>(false);
  //create block to avoid to open many times the same window
  public openProtection(): boolean {
    if (this.currentBlock) {
      return this.currentBlock;
    }

    this.currentBlockObs.next(true);
    setTimeout(() => {
      this.currentBlockObs.next(false);
    }, 5000);

    return false;
  }

  sportsToken = new BehaviorSubject<string>('');

  //return the observable of the block
  private readonly loginPipe = map(
    (info: GetCurrentLoginInformationsOutput) => {
      const currencyModel = new CurrencyModel();
      currencyModel.currencySymbol = info.agtSession.currencyCode;
      currencyModel.currencyId = info.agtSession.countryId;
      this.onCurrencyLoad.next(currencyModel);
      return info;
    }
  );

  private readonly tokenObserver = (token: string) => {
    if (token) {
      this.localStorageService
        .get('HASH')
        .pipe()
        .subscribe((hash) => {
          if (hash) {
            this.localStorageService
              .get('CLIENTID')
              .pipe()
              .subscribe((clientId) => {
                if (clientId) {
                  const authModel: AuthenticateResultModel =
                    AuthenticateResultModel.fromJS({
                      hash: hash,
                      userId: clientId,
                      accessToken: token,
                    });
                  this.saveAuthLocal(authModel).subscribe();
                  this.setAuthCookies(authModel);
                  this.signalRLogin(authModel);
                  this.notifyAuth(authModel);
                } else {
                  this.onLoading.next(false);
                }
              });
          } else {
            this.localStorageService
              .get('affiliateid')
              .pipe()
              .subscribe((data) => {
                if (
                  !!data &&
                  !!this.dataFromUrlService.getData('affiliateid')
                ) {
                  this.localStorageService.delete('affiliateid').subscribe();
                }
              });
            this.onLoading.next(false);
          }
        });
    } else {
      // const hash = this.cookieService.getCookie('HASH');
      // const clientId = this.cookieService.getCookie('CLIENTID');
      const hash = this.cookieService.get('HASH');
      const clientId = this.cookieService.get('CLIENTID');
      // console.log('Cookie-Hash:' + hash);
      // console.log('Cookie-ClientId:' + clientId);
      if (hash && clientId) {
        this.authenticateHash(clientId, hash);
      }
      this.onLoading.next(false);
    }
  };

  connectionStateObs: PartialObserver<ConnectionState> = {
    next: (connectionState) => {
      if (this.onAuth.value !== null) {
        this.connectionStatus = connectionState.status;
      }
    },
    error: (e) => {},
  };

  constructor(
    private tokenAuthServiceProxy: TokenAuthServiceProxy,
    public signalR: SignalRService,
    public jwtHelper: JwtHelperService,
    private session: SessionServiceProxy,
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private dataFromUrlService: DataFromUrlService,
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorageService: StorageMap
  ) {
    this.onAuth.pipe().subscribe((p) => {
      this.currentAuthInfo = p;
      if (p) {
        this.session
          .getCurrentLoginInformations()
          .pipe(this.loginPipe)
          .subscribe();
      }
    });

    this.initializeAuthentication();

    this.currentBlockObs.subscribe((p) => {
      this.currentBlock = p;
    });
    this.sportsToken.subscribe({
      next: (token) => {
        this.localStorageService.set('sportsToken', token).subscribe({
          next: () => {},
        });
      },
    });
  }

  public authenticateHash(clientId: string, hash: string) {
    return new Observable<boolean>((subscribe) => {
      this.tokenAuthServiceProxy
        .authenticateHash(+clientId, hash)
        .pipe()
        .subscribe((authModel) => {
          this.saveLogin(authModel);
          subscribe.next(true);
        });
    });
  }

  getCurrency(): Observable<CurrencyModel> {
    return this.onCurrencyLoad.asObservable();
  }

  isLogged(): boolean {
    return this.onAuth.value !== null;
  }

  public login(authModel: AuthenticateModel) {
    return new Observable<AuthenticateResultModel>((obs) => {
      this.authModel = authModel;
      if (this.authModel) {
        this.tokenAuthServiceProxy
          .authenticate(this.authModel)
          .pipe()
          .subscribe(
            (next) => {
              obs.next(next);
              this.saveLogin(next);
            },
            (error) => {
              obs.error(error);
              obs.complete();
            }
          );
      }
    });
  }

  public logOut() {
    this.clearAuthenticationData();
    this.signalR.disconnect();
    this.onAuth.next(null);
    this.localStorageService.delete('auth-access-token').subscribe(() => {});
    this.localStorageService.delete('CLIENTID').subscribe(() => {});
    this.localStorageService.delete('HASH').subscribe(() => {});
    this.localStorageService
      .delete('affiliateid')
      .subscribe((bool) =>
        bool ?this.localStorageService.delete('affiliateid') : ''
      );
    this.localStorageService.delete('sportsToken').subscribe(() => {
      this.sportsToken.next('');
    });

    const domain = this.getMainDomain(window.location.hostname);
    this.cookieService.delete('CLIENTID', '/', domain, true, 'Strict');
    this.cookieService.delete('HASH', '/', domain, true, 'Strict');
    this.cookieService.delete('auth-access-token', '/', domain, true, 'Strict');
    this.httpClient
      .get('https://pt.playbonds.com/Account/Logout/')
      .pipe()
      .subscribe();
    this.miles = 0;
    this.messageCount = 0;
  }

  getCurrentAuth(): Observable<AuthModel> {
    return this.onAuth;
    /* return new Observable<AuthModel>((resolve) => {

      if (this.currentAuthInfo !== undefined) {
        resolve.next(this.currentAuthInfo);
      }

      this.onAuth.pipe().subscribe(p => resolve.next(p));
     });*/
  }

  saveLogin(authModel: AuthenticateResultModel) {
    // console.log('saveLogin');
    this.setAuthCookies(authModel);
    if (!!Globals._signalR) {
      this.signalRLogin(authModel).subscribe();
    }
    this.saveAuthLocal(authModel).subscribe(
      () => {
        if (this.onAuth.value?.hash !== authModel?.hash) {
          // console.log('notifyAuth');
          this.notifyAuth(authModel);
        }
      },
      (e) => console.error(e)
    );
  }

  private signalRLogin(authModel: AuthenticateResultModel): Observable<any> {
    return this.signalR.connect().pipe(
      retry(5),
      switchMap(() => {
        return this.signalR.login(+authModel.userId, authModel.hash);
      })
    );
  }

  private saveAuthLocal(authModel: AuthenticateResultModel) {
   this.localStorageService.set('affiliateid', authModel.affiliateId?.toString());
    return merge(
      this.localStorageService.set('auth-access-token', authModel.accessToken),
      this.localStorageService.set('HASH', authModel.hash),
      this.localStorageService.set('CLIENTID', authModel.userId),
      this.localStorageService.set('affiliateid', authModel.affiliateId)
    );
  }

  notifyAuth(authModel: AuthenticateResultModel) {
    let auth = this.decodeAccessToken(authModel.accessToken);
    if (!!auth) {
      auth.hash = authModel.hash;
      this.onAuth.next(auth);
      this.onLoading.next(false);
    }
  }

  decodeAccessToken(token: string): AuthModel {
    if (typeof token === 'string') {
      const tokenInfo = this.jwtHelper.decodeToken(token);

      // It only check while not use and implement a refresh token functionality.
      if (!this.jwtHelper.isTokenExpired(token)) {
        const auth = new AuthModel();
        auth.SiteId =
          tokenInfo[
            'http://www.aspnetboilerplate.com/identity/claims/tenantId'
          ];
        auth.UserId = tokenInfo['sub'];
        auth.Username =
          tokenInfo[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
          ];
        auth.FullName = auth.Username;
        return auth;
      } else {
        // Delete token expired
        this.localStorageService.delete('auth-access-token');
      }
    }
  }

  private setAuthCookies(authModel: AuthenticateResultModel) {
    // console.log('setAuthCookies');
    const domain = this.getMainDomain(window.location.hostname);
    // console.log('domain: ', domain);
    this.cookieService.set(
      'auth-access-token',
      authModel.accessToken,
      365,
      '/',
      domain,
      true,
      'Strict'
    );
    this.cookieService.set(
      'HASH',
      authModel.hash,
      365,
      '/',
      domain,
      true,
      'Strict'
    );
    this.cookieService.set(
      'CLIENTID',
      authModel.userId.toString(),
      365,
      '/',
      domain,
      true,
      'Strict'
    );
    // this.setCookie(
    //   'auth-access-token',
    //   authModel.accessToken,
    //   365,
    //   true,
    //   'Strict',
    //   domain
    // );
    // this.setCookie('HASH', authModel.hash, 365, true, 'Strict', domain);
    // this.setCookie(
    //   'CLIENTID',
    //   authModel.userId.toString(),
    //   365,
    //   true,
    //   'Strict',
    //   domain
    // );

    // console.log('Cookie-Auth:' + this.cookieService.get('auth-access-token'));
    // console.log('Cookie-Hash:' + this.cookieService.get('HASH'));
    // console.log('Cookie-ClientId:' + this.cookieService.get('CLIENTID'));
  }
  getMainDomain(hostname) {
    let result;
    // Eliminar posibles 'www' al inicio
    if (hostname.startsWith('www.')) {
      hostname = hostname.substring(4);
    }

    // Si el hostname contiene "nip", devuelve '.nip.io'
    if (hostname.includes('nip')) {
      result = '.nip.io';
    }

    let parts = hostname.split('.');

    // Verifica si el dominio es '.com', '.org', '.net', etc.
    if (parts.length > 2) {
      // Une las dos últimas partes para obtener '.com', '.co.uk', etc.
      result = '.' + parts.slice(-2).join('.');
    } else {
      // En caso de dominios como 'ejemplo.com'
      result = '.' + parts.join('.');
    }
    return result;
  }

  private initializeAuthentication(): void {
    const token = this.cookieService.get('auth-access-token');
    const hash = this.cookieService.get('HASH');
    const clientId = this.cookieService.get('CLIENTID');

    if (token) {
      if (hash && clientId) {
        // Si hay token, hash y clientId en cookies, actualiza los datos de la sesión.
        const authModel: AuthenticateResultModel =
          AuthenticateResultModel.fromJS({
            hash: hash,
            userId: clientId,
            accessToken: token,
          });
        this.saveLogin(authModel);
      } else {
        // Si solo hay token, decodifica y obtiene hash y clientId del token.
        const decoded = this.decodeAccessToken(token);
        if (decoded && decoded.hash && decoded.UserId) {
          const authModel: AuthenticateResultModel =
            AuthenticateResultModel.fromJS({
              hash: decoded.hash,
              userId: decoded.UserId,
              accessToken: token,
            });
          this.saveLogin(authModel);
        } else {
          // Si el token no tiene la información necesaria, verifica localStorage.
          this.checkLocalStorageForAuth();
        }
      }
    } else if (hash && clientId) {
      // Si no hay token pero sí hash y clientId en cookies, autentica con authenticateHash.
      this.authenticateHash(clientId, hash);
    } else {
      // Si no hay datos relevantes en las cookies, verifica localStorage.
      this.checkLocalStorageForAuth();
    }
  }

  private checkLocalStorageForAuth(): void {
    this.localStorageService
      .get('auth-access-token')
      .pipe(
        switchMap((token) => {
          if (token) {
            return this.localStorageService
              .get('HASH')
              .pipe(
                switchMap((hash) =>
                  this.localStorageService
                    .get('CLIENTID')
                    .pipe(map((clientId) => ({ token, hash, clientId })))
                )
              );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((data: { token: string; hash: string; clientId: string }) => {
        if (data && data.token && data.hash && data.clientId) {
          // Si hay token, hash y clientId en localStorage, actualiza los datos de la sesión.
          const authModel: AuthenticateResultModel =
            AuthenticateResultModel.fromJS({
              hash: data.hash,
              userId: data.clientId,
              accessToken: data.token,
            });
          this.saveLogin(authModel);
        } else if (data && data.token) {
          // Si solo hay token, decodifica y obtiene hash y clientId del token.
          const decoded = this.decodeAccessToken(data.token);
          if (decoded && decoded.hash && decoded.UserId) {
            const authModel: AuthenticateResultModel =
              AuthenticateResultModel.fromJS({
                hash: decoded.hash,
                userId: decoded.UserId,
                accessToken: data.token,
              });
            this.saveLogin(authModel);
          }
        } else {
          // Si no hay datos para autenticación, limpia la información.
          this.clearAuthenticationData();
        }
      });
  }

  private clearAuthenticationData() {
    // Limpiar datos en LocalStorage
    forkJoin([
      this.localStorageService.delete('auth-access-token'),
      this.localStorageService.delete('HASH'),
      this.localStorageService.delete('CLIENTID'),
      this.localStorageService.delete('affiliateid'),
      this.localStorageService.delete('sportsToken'),
    ]).subscribe({
      complete: () => {},
    });

    let domain = "";

    if (isPlatformBrowser(this.platformId)) {
      domain = this.getMainDomain(window.location.hostname);

    // Limpiar cookies
      this.cookieService.delete('auth-access-token', '/', domain, true, 'Strict');
      this.cookieService.delete('HASH', '/', domain, true, 'Strict');
      this.cookieService.delete('CLIENTID', '/', domain, true, 'Strict');

      // Resetear cualquier estado relevante en el servicio
      this.onAuth.next(null);
      this.currentAuthInfo = null;
      this.miles = 0;
      this.messageCount = 0;
      this.sportsToken.next('');

    // Desconectar de cualquier servicio externo si es necesario, como SignalR
      this.signalR.disconnect();
    }
    // Otras limpiezas o reseteos que puedan ser necesarios
    // ...
  }
}
