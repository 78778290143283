import { DOCUMENT, isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject, Observable, PartialObserver, Subscription
} from 'rxjs';
import { ProfileServiceProxy } from '../service-proxies/service-proxies';
import { UrlService } from './url.service';
import { UserService } from './user.services';

@Injectable({
  providedIn: 'root',
})
export class GlobalTranslateService implements OnDestroy {
  // this language will be used as a fallback when a translation isn't found in the current language

  public langs = ['pt', 'en', 'es'];

  private defaultLang = 'pt';

  public currentLang: string;
  public lang = new BehaviorSubject<string>(this.defaultLang);
  private loading = new BehaviorSubject<boolean>(true);
  private noLanguageURL = false;
  private browserLang = this.defaultLang;

  private languageSubscription: Subscription = null;

  /**
   * @description Set the current languaje on the app and executes the needed functions returning an PartialObserver
   * @author Arroyito
   * @date 2019-03-14
   * @private
   * @param {string} langua languaje to be set
   * @returns {PartialObserver<boolean>} returns the Observer for the suscribe function
   * @memberof GlobalTranslateService
   */
  private setlangObserver(lang: string): PartialObserver<boolean> {
    return {
      next: (flag) => {
        this.urlService.currentLanguaje = lang;
        // this.correctUrl(lang);
        this.lang.next(lang);
      },
    };
  }

  /**
   * @description Returns the language depending the URL or LocalStorage or Browser
   * @author Arroyito
   * @date 2019-03-18
   * @private
   * @returns {string}
   * @memberof GlobalTranslateService
   */
  private setDefaultLang() {
    this.lang.subscribe((l2) => {
      this.currentLang = l2;
      this.urlService.currentLanguaje = l2;
    });
    let lang = this.langs[0];
    // Set Language
    let urlArray = [];
    if (isPlatformServer(this.platformId)) {
      urlArray = this.document.location.pathname.split('/');
    } else {
      urlArray = this.document.location.pathname.split('/');
    }
    if (
      urlArray.length > 2 &&
      (urlArray[1] === 'pt' || urlArray[1] === 'en' || urlArray[1] === 'es')
    ) {
      // * #region  Set Language from URL */
      lang = urlArray[1];
      this.lang.next(urlArray[1]);
      /* #endregion */
    } else if (isPlatformBrowser(this.platformId)) {
      this.noLanguageURL = true;
      // * #region Set Language by the LocalStorage or the Browser
      this.localStorage
        .get('userLanguage')
        .subscribe((storedLanguage: { value: string }) => {
          if (storedLanguage === null) {
            storedLanguage.value = this.translate.getBrowserLang();
          }
          this.urlService.currentLanguaje = storedLanguage.value;
          this.lang.next(storedLanguage.value);
          this.changeLanguage(storedLanguage.value);
          // #endregion
        });
    }
  }

  constructor(
    private translate: TranslateService,
    public userService: UserService,
    public profileService: ProfileServiceProxy,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location,
    public urlService: UrlService,
    private localStorage: StorageMap,
    @Inject(DOCUMENT) private document: any
  ) {
    this.setDefaultLang();
    // this.changeLanguage(this.defaultLang);
  }

  /**
   * Observable telling when a new  is being loaded
   * @returns Observable<boolean>
   */
  loadingLanguage(): Observable<boolean> {
    return this.loading;
  }

  currentLanguage(): Observable<string> {
    return this.lang;
  }

  changeLanguage(lang: string) {
    if (this.currentLang !== lang) {
      this.loading.next(true);
      if (this.userService !== undefined) {
        this.userService.getCurrentAuth().subscribe((auth) => {
          if (auth !== null && auth !== undefined) {
            this.profileService.setClientLanguage(lang).subscribe(
              () => {
                if (this.languageSubscription !== null) {
                  this.languageSubscription.unsubscribe();
                  this.languageSubscription = this.localStorage
                    .set('userLanguage', lang)
                    .subscribe(this.setlangObserver(lang));
                } else {
                  this.languageSubscription = this.localStorage
                    .set('userLanguage', lang)
                    .subscribe(this.setlangObserver(lang));
                }
              },
              () => {
                if (this.languageSubscription !== null) {
                  this.languageSubscription.unsubscribe();
                  this.languageSubscription = this.localStorage
                    .set('userLanguage', lang)
                    .subscribe(this.setlangObserver(lang));
                } else {
                  this.languageSubscription = this.localStorage
                    .set('userLanguage', lang)
                    .subscribe(this.setlangObserver(lang));
                }
              }
            );
          } else {
            if (this.languageSubscription !== null) {
              this.languageSubscription.unsubscribe();
              this.languageSubscription = this.localStorage
                .set('userLanguage', lang)
                .subscribe(this.setlangObserver(lang));
            } else {
              this.languageSubscription = this.localStorage
                .set('userLanguage', lang)
                .subscribe(this.setlangObserver(lang));
            }
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.languageSubscription !== null) {
      this.languageSubscription.unsubscribe();
    }
  }

  public correctUrl(lang: string) {
    // console.log('llegue 4');
    let urlString = '';
    let element;
    let correctedurl: string;
    const path = this.location.path();

    if (path.match(/\/es\/|\/en\/|\/pt\//g)) {
      correctedurl = path.replace(/\/es\/|\/en\/|\/pt\//g, '/' + lang + '/');
    } else {
      correctedurl = '/' + lang + path;
    }

    const urlArray = correctedurl.split('/');

    urlString = '/' + urlArray[1];
    for (let i = 2; i < urlArray.length; i++) {
      element = urlArray[i];
      urlString += '/';
      const aux = this.urlService.correctRoute(element, lang);
      element = aux !== undefined ? aux : element;
      urlString += element;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.router.navigateByUrl(urlString.toString());
    }
  }

  public correctUrl2(lang: string) {
    // console.log('llegue 4');
    let urlString = '';
    let element;
    let correctedurl: string;
    const path = this.location.path();

    if (path.match(/\/es\/|\/en\/|\/pt\//g)) {
      correctedurl = path.replace(/\/es\/|\/en\/|\/pt\//g, '/' + lang + '/');
    } else {
      correctedurl = '/' + lang + path;
    }

    const urlArray = correctedurl.split('/');

    urlString = '/' + urlArray[1];
    for (let i = 2; i < urlArray.length; i++) {
      element = urlArray[i];
      urlString += '/';
      const aux = this.urlService.correctRoute(element, lang);
      element = aux !== undefined ? aux : element;
      urlString += element;
    }

    return urlString.toString();
  }

  public configureTranslate(translate: TranslateService): Observable<any> {
    translate.addLangs(this.langs);

    this.lang.subscribe((l) => {
      translate
        .use(l)
        .pipe()
        .subscribe((data) => {
          this.loading.next(false);
        });
    });

    return translate.use(this.currentLang);
  }

  public getLangs(): string[] {
    return this.langs;
  }

  /**
   * @description
   * @author Arroyito
   * @date 2019-05-07
   * @param {number} channelId
   * @returns {string} Channel tag for Routing
   * @memberof GlobalTranslateService
   */
  public getChannelRouterTag(channelId: number): string {
    let channelTag: string = null;
    switch (channelId) {
      case 1:
        channelTag = 'video-bingo';
        break;
      case 2:
        channelTag = 'casino';
        break;
      case 10:
        channelTag = 'sports';
        break;
      case 45:
        channelTag = 'bingo-room';
        break;
    }
    return channelTag;
  }
}
