import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
  registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HubConnectionFactory } from '@ssv/signalr-client';
import { Globals } from 'src/shared/globalVariables';
import {
  HttpConfiguration,
  HttpInterceptorService,
} from 'src/shared/http.interceptor';
import {
  API_BASE_URL,
  ProfileServiceProxy,
  SessionServiceProxy,
  TokenAuthServiceProxy,
  TranslateServiceProxy,
  UserServiceProxy,
} from 'src/shared/service-proxies/service-proxies';
import { CamaleonTranslateLoader } from 'src/shared/services/CamaleonTranslateLoader';
import { DataFromUrlService } from 'src/shared/services/dataFromUrl.service';
import { GlobalTranslateService } from 'src/shared/services/global.translate.service';
import { UserService } from 'src/shared/services/user.services';
import { AppComponent } from './app.component';
import { NgxLoadingModule } from 'ngx-loading';

registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

export function CameleonLoader(t: TranslateServiceProxy) {
  return new CamaleonTranslateLoader(t, 'website');
}

export function getRemoteServiceBaseUrl(platformId: Object): string {
  /*if (isPlatformServer(platformId)) {
    return 'http://api'; // When rendering using server
  }*/

  return Globals._APIURl; // comentar para cambiar a entorno de produccion
  // return 'https://api.playbonds.com'; // descomentar para cambiar a entorno de produccion
  // return 'https://playbonds-staging-api.staging.147.124.216.129.nip.io'; // descomentar para cambiar a entorno de staging
}

export function jwtOptionsFactory(localStorage: StorageMap) {
  return {
    tokenGetter: () => {
      return localStorage.get('auth-access-token').toPromise();
    },
    allowedDomains: [
      'localhost:21021',
      'api.playbonds.com',
      'playbonds.com',
      'playbonds-website-v2019-api.ativogames.com',
      'playbonds-website-v2019-notificate.ativogames.com',
      '184.173.170.35:30929',
      'api-for-cashio.netcore.179.27.73.27.xip.io',
      'playbonds-staging-api.staging.147.124.216.129.nip.io',
      'playbonds-staging.staging.147.124.216.129.nip.io'
    ],
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxLoadingModule.forRoot({}),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./website/website.module').then((m) => m.WebsiteModule),
        },
      ],
      {
        useHash: false,
        scrollPositionRestoration: 'top',
        initialNavigation: 'enabledBlocking',
        //relativeLinkResolution: 'legacy'
      }
    ),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CameleonLoader,
        deps: [TranslateServiceProxy],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageMap],
      },
    }),
    CommonModule,
  ],
  providers: [
    HttpConfiguration,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
      deps: [HttpConfiguration, UserService, GlobalTranslateService],
    },
    {
      provide: API_BASE_URL,
      useFactory: getRemoteServiceBaseUrl,
      deps: [PLATFORM_ID],
    },
    TranslateServiceProxy,
    ProfileServiceProxy,
    TokenAuthServiceProxy,
    SessionServiceProxy,
    UserServiceProxy,
    DataFromUrlService,
    HubConnectionFactory,
  ],
  bootstrap: [AppComponent],
})
export class RootModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    // console.log(`Running ${platform}`);
    if (platform === 'in the browser') {
    }
  }
}
