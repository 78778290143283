import { isPlatformServer } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { GlobalTranslateService } from './services/global.translate.service';
import { UserService } from './services/user.services';

export interface IValidationErrorInfo {
  message: string;

  members: string[];
}

export interface IErrorInfo {
  code: number;

  message: string;

  details: string;

  validationErrors: IValidationErrorInfo[];
}

export interface IAjaxResponse {
  success: boolean;

  result?: any;

  targetUrl?: string;

  error?: IErrorInfo;

  unAuthorizedRequest: boolean;

  __abp: boolean;
}

@Injectable()
export class HttpConfiguration {

  constructor() {}

  defaultError = <IErrorInfo>{
    message: 'An error has occurred!',
    details: 'Error details were not sent by server.',
  };

  defaultError401 = <IErrorInfo>{
    message: 'You are not authenticated!',
    details:
      'You should be authenticated (sign in) in order to perform this operation.',
  };

  defaultError403 = <IErrorInfo>{
    message: 'You are not authorized!',
    details: 'You are not allowed to perform this operation.',
  };

  defaultError404 = <IErrorInfo>{
    message: 'Resource not found!',
    details: 'The resource requested could not be found on the server.',
  };

  logError(error: IErrorInfo): void {
    console.log(error);
  }

  showError(error: IErrorInfo): any {
    if (error.message) {
      return console.log(
        error.message,
        error.message || this.defaultError.message
      );
    } else {
      return console.log(error.message || this.defaultError.message);
    }
  }

  handleTargetUrl(targetUrl: string): void {
    if (!targetUrl) {
      location.href = '/';
    } else {
      location.href = targetUrl;
    }
  }

  handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
    const self = this;

    if (messagePromise) {
      messagePromise.done(() => {
        this.handleTargetUrl(targetUrl || '/');
      });
    } else {
      self.handleTargetUrl(targetUrl || '/');
    }
  }

  handleNonAbpErrorResponse(response: HttpResponse<any>) {
    const self = this;

    switch (response.status) {
      case 401:
        self.handleUnAuthorizedRequest(console.log(self.defaultError401), '/');
        break;
      case 403:
        console.log(self.defaultError403);
        break;
      case 404:
        console.log(self.defaultError404);
        break;
      default:
        console.log(self.defaultError);
        break;
    }
  }

  handleAbpResponse(
    response: HttpResponse<any>,
    ajaxResponse: IAjaxResponse
  ): HttpResponse<any> {
    let newResponse: HttpResponse<any>;

    if (ajaxResponse.success) {
      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (ajaxResponse.targetUrl) {
        this.handleTargetUrl(ajaxResponse.targetUrl);
      }
    } else {
      newResponse = response.clone({
        body: ajaxResponse.error,
        statusText: ajaxResponse.error.message,
      });

      if (!ajaxResponse.error) {
        ajaxResponse.error = this.defaultError;
      }

      this.logError(ajaxResponse.error);
      this.showError(ajaxResponse.error);

      if (response.status === 401) {
        this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
      }
    }

    return newResponse;
  }

  getAbpAjaxResponseOrNull(response: HttpResponse<any>): IAjaxResponse | null {
    if (!response || !response.headers) {
      return null;
    }

    const contentType = response.headers.get('Content-Type');
    if (!contentType) {
      console.log('Content-Type is not sent!');
      return null;
    }

    if (contentType.indexOf('application/json') < 0) {
      console.log('Content-Type is not application/json: ' + contentType);
      return null;
    }

    const responseObj = JSON.parse(JSON.stringify(response.body));
    if (!responseObj.__abp) {
      return null;
    }

    return responseObj as IAjaxResponse;
  }

  handleResponse(response: HttpResponse<any>): HttpResponse<any> {
    const ajaxResponse = this.getAbpAjaxResponseOrNull(response);
    if (ajaxResponse == null) {
      return response;
    }

    return this.handleAbpResponse(response, ajaxResponse);
  }

  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        if (typeof blob === 'string') {
          observer.next(blob);
          observer.complete();
        } else if (typeof blob === 'object' && blob.data !== undefined) {
          observer.next(blob.data);
          observer.complete();
        } else {
          const reader = new FileReader();
          reader.onload = function () {
            observer.next(this.result);
            observer.complete();
          };
          reader.readAsText(blob);
        }
      }
    });
  }
}

export class HttpInterceptorService implements HttpInterceptor {
  private url: string;
  protected configuration: HttpConfiguration;
  protected SiteId = 1;
  protected Lang = 'pt';


  constructor(
    configuration: HttpConfiguration,
    public userService: UserService,
    public translateService: GlobalTranslateService
  ) {
    this.configuration = configuration;

    if (this.userService) {
      this.userService.getCurrentAuth().subscribe((p) => {
        if (p !== undefined && p != null) {
          this.SiteId = p.SiteId;
        }
      });
    }
    if (this.translateService) {
      this.translateService.urlService.currentLanguaje =
        this.translateService.urlService.currentLanguaje;
      // this.translateService.urlService.currentLanguaje = currentLang;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const interceptObservable = new Subject<HttpEvent<any>>();
    const modifiedRequest = this.normalizeRequestHeaders(request);

    //Agregamos para exluir dominios del interceptor
    // Definir el dominio que deseas excluir
    const excludedDomain = 'https://hcms.playbonds.com/items/'; //si ponia https://hcms.playbonds.com solo se rompia
    // Verificar si la solicitud es para ese dominio
    if (request.url.includes(excludedDomain)) {
      // Si la solicitud es para el dominio excluido, pasarla sin modificarla
      return next.handle(request);
    }


    next.handle(modifiedRequest).subscribe(
      (event: HttpEvent<any>) => {
        this.handleSuccessResponse(event, interceptObservable);
      },
      (error: any) => {
        return this.handleErrorResponse(error, interceptObservable);
      }
    );
    return interceptObservable;
  }

  protected handleSuccessResponse(
    event: HttpEvent<any>,
    interceptObservable: Subject<HttpEvent<any>>
  ): void {
    const self = this;

    if (event instanceof HttpResponse) {
      if (
        typeof event.body === 'string' ||
        typeof event.body === 'object' ||
        (event.body instanceof Blob &&
          event.body.type &&
          event.body.type.indexOf('application/json') >= 0)
      ) {
        const clonedResponse = event.clone();

        self.configuration.blobToText(event.body).subscribe((json) => {
          const responseBody = json === 'null' ? {} : JSON.parse(json);

          const modifiedResponse = self.configuration.handleResponse(
            event.clone({
              body: responseBody,
            })
          );

          let httpEvent;

          if (isPlatformServer) {
            httpEvent = modifiedResponse.clone({
              body: JSON.stringify(modifiedResponse.body),
            });
          } else {
            httpEvent = modifiedResponse.clone({
              body: new Blob([JSON.stringify(modifiedResponse.body)], {
                type: 'application/json',
              }),
            });
          }

          interceptObservable.next(httpEvent);

          interceptObservable.complete();
        });
      } else {
        interceptObservable.next(event);
        interceptObservable.complete();
      }
    }
  }




  protected handleErrorResponse(
    error: any,
    interceptObservable: Subject<HttpEvent<any>>
  ): Observable<any> {
    const errorObservable = new Subject<any>();

    if (
      typeof error.error === 'string' ||
      typeof error.error === 'object' ||
      error.error instanceof Blob
    ) {
      this.configuration.blobToText(error.error).subscribe((json) => {
        try {
          const errorBody =
            json === '' || json === 'null' ? {} : JSON.parse(json);
          errorObservable.complete();
          interceptObservable.error(errorBody.error);
          interceptObservable.complete();
        } catch (ex) {
          console.log('error handleErrorResponse2' ,json);
          console.error(ex);
        }

        return of({});
      });
    }

    this.configuration.blobToText(error.error).subscribe((json) => {
      const errorBody = json === '' || json === 'null' ? {} : JSON.parse(json);
      const errorResponse = new HttpResponse({
        headers: error.headers,
        body: errorBody,
        statusText: errorBody.error.message,
      });

      const ajaxResponse =
        this.configuration.getAbpAjaxResponseOrNull(errorResponse);

      if (ajaxResponse != null) {
        this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
      } else {
        this.configuration.handleNonAbpErrorResponse(errorResponse);
      }

      errorObservable.complete();

      // prettify error object.
      error.error = errorBody;
      interceptObservable.error(error);
      interceptObservable.complete();
    });

    return errorObservable;
  }

  protected normalizeRequestHeaders(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    let modifiedHeaders = new HttpHeaders();
    modifiedHeaders = request.headers
      .set('Pragma', 'no-cache')
      .set('Cache-Control', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    modifiedHeaders = this.addXRequestedWithHeader(modifiedHeaders);
    modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
    modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
    modifiedHeaders = this.addTenantIdHeader(modifiedHeaders);

    let params = request.params;
    if (this.translateService.urlService.currentLanguaje) {
      params = params.append(
        'culture',
        this.translateService.urlService.currentLanguaje
      );
    }

    return request.clone({
      headers: modifiedHeaders,
      params: params,
    });
  }

  protected addXRequestedWithHeader(headers: HttpHeaders): HttpHeaders {
    if (headers) {
      headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    }

    return headers;
  }

  protected addAspNetCoreCultureHeader(headers: HttpHeaders): HttpHeaders {
    if (
      this.translateService.urlService.currentLanguaje &&
      headers &&
      !headers.has('.AspNetCore.Culture')
    ) {
      headers = headers.set(
        '.AspNetCore.Culture',
        this.translateService.urlService.currentLanguaje
      );
    }

    return headers;
  }

  protected addAcceptLanguageHeader(headers: HttpHeaders): HttpHeaders {
    if (
      this.translateService.urlService.currentLanguaje &&
      headers &&
      !headers.has('Accept-Language')
    ) {
      headers = headers.set(
        'Accept-Language',
        this.translateService.urlService.currentLanguaje
      );
    }

    return headers;
  }

  protected addTenantIdHeader(headers: HttpHeaders): HttpHeaders {
    if (this.SiteId && headers && !headers.has('Abp.TenantId')) {
      headers = headers.set('Abp.TenantId', this.SiteId.toString());
    }

    return headers;
  }
}
